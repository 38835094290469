<template>
	<main>
		<section class="service-area pb-70 pt-100">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center mb-40">
						<div class="section-title service-title">
							<h2>Who we are ?</h2>
							<p>We work on ideas that work for you.</p>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-12 col-md-6 mb-30">
						<p>
							We are in software development industry for long. Our product
							range is growing day and night. We have helped customers gain the
							most out of their respective business and that too in an organized
							manner.
						</p>
						<p>
							By joining our affiliate program you too can help your customer
							achieve great heights in their business. We offer all our products
							and services for you to sell to your customers and help you
							generate revenues for yourself.
						</p>
						<p>
							<i class="fa fa-hand-o-right pr-10" aria-hidden="true"></i>Join
							list of enthusist partners all over who leverage our products and
							services to deliver real value to their customers and generate
							revenue
						</p>
					</div>
				</div>
			</div>
		</section>

		<section class="service-area grey-bg pb-70 pt-100">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center mb-40">
						<div class="section-title service-title">
							<h2>What we expect from you ?</h2>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/skill.svg" alt="FreightMan" />
							</div>

							<h4>Skills</h4>
							<p>
								If you are, a computer professional or an accountant or a
								software developer or a marketing executive or working in a
								company we have solution for, you will definitely fit into our
								thing of schemes. Besides having these skills, We expect you to
								have enthusiasm to grow and build a strong business relationship
								with your clients so that they get maximum out our products and
								services.
							</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/reading-book.svg" alt="FreightMan" />
							</div>

							<h4>Learn</h4>
							<p class="mb-40">
								Our affiliate program involves a fair bit of learning curve. To
								be able to sell some of our products and services, we require
								you to be ready to educate yourself. Having knowledge of each
								product or service that you want to sell will help your clients
								get their answers fairly quickly and which in turn help you find
								more business in terms of references.
							</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/customer.svg" alt="FreightMan" />
							</div>

							<h4>Market</h4>
							<p>
								This is obvious. You need to reach to the people to sell them
								something. We require you to be able to reach to potential
								customers, fix appointments and demonstrate our products and
								services to them. This is all up to you to find resources and
								reach them, demonstrate and help them find answers of their
								questions. Finally convince them to buy our products and
								services.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="service-area grey-bg pb-70 pt-20">
			<div class="container">
				<div class="row">
					<div class="col-xl-12 text-center mb-40">
						<div class="section-title service-title">
							<h2>What you will get to grow ?</h2>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/presentation.svg" alt="FreightMan" />
							</div>

							<h4>Training</h4>
							<p class="mb-40">
								As mentioned earlier, You need to gain knowledge of some of our
								products and services to sell them. We will provide training for
								each and every product or service you are interested in. We will
								also provide training on how to get resources, reach their and
								convince them.
							</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/gift.svg" alt="FreightMan" />
							</div>

							<h4>Reward</h4>
							<p>
								We offer attractive margins for you on selling our products and
								services. You are free to decide whether we directly bill to
								your client and reward you with your commission or you will
								purchase our products and services at discounted rates from us
								and bill to your clients at your own price.
							</p>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 text-center mb-30">
						<div class="features-wrap">
							<div class="features-icon">
								<img src="assets/icons/question.svg" alt="FreightMan" />
							</div>

							<h4>Help</h4>
							<p class="mb-65">
								If you find it difficult, to convince your customer or help them
								find an answer of their question, We will definitely help you.
								In case, if your client is having difficulty with any of our
								product or service, we will help you or your client even after
								sales.
							</p>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-xl-12 text-center mb-40 pt-50">
						<a href="javascript:void(0)" @click="$router.push('/contact')" class="btn" data-animation="fadeInUp" data-delay=".6s"
							>Join Now</a
						>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
export default {
  name: "becomepartner",
};
</script>

<style></style>
